/* eslint-disable import/no-cycle */
import moment from 'moment';
import reduxStore from '../store';
import { setSummaryTableRowValues } from '../reducers/summaryTable';
import { convertToLocal } from './convertTime';

export default async (forecast) => {
  const gaugeThresholds = await reduxStore.getState().gaugeThresholds.data;
  const currentGaugeThresholds = gaugeThresholds.find((gauge) => Number(gauge.gauge_id) === Number(forecast[0].gauge_id));
  const hcfcdForecasts = forecast.filter(({ reading_source }) => reading_source === 'H0');
  const v0Forecasts = forecast.filter(({ reading_source }) => reading_source === 'V0');
  const m0Forecasts = forecast.filter(({ reading_source }) => reading_source === 'M0');
  const r0Forecasts = forecast.filter(({ reading_source }) => reading_source === 'R0');
  const n0Forecasts = forecast.filter(({ reading_source }) => reading_source === 'N0');
  const a0Forecasts = forecast.filter(({ reading_source }) => reading_source === 'A0');
  const g0Forecasts = forecast.filter(({ reading_source }) => reading_source === 'G0');
  const sortedForecasts2dArray = [hcfcdForecasts, v0Forecasts, m0Forecasts, r0Forecasts,n0Forecasts,a0Forecasts,g0Forecasts];
  for (let i = 0; i < sortedForecasts2dArray.length; i += 1) {
    let rowKey = '';
    if (i === 0) rowKey = 'H0';
    else if (i === 1) rowKey = 'V0';
    else if (i === 2) rowKey = 'M0';
    else if (i === 3) rowKey = 'R0';
    else if (i === 4) rowKey = 'N0';
    else if (i === 5) rowKey = 'A0';
    else if (i === 6) rowKey = 'G0';
    const summaryTableStateConfig = {
      rowKey,
      keyValueArray: [],
    };
    sortedForecasts2dArray[i].forEach(({ device, full_readings, max_reading }) => {
      if (device === 'STAGE') {
        const readings = [...full_readings];
        readings.sort((a, b) => b.reading_time - a.reading_time);
        let peakPoint = full_readings[full_readings.length - 1];
        for (let i = 0; i < readings.length; i += 1) {
          if (readings[i].reading_value >= peakPoint.reading_value) {
            // if values are the same, take the earlier value
            if (Number(readings[i].reading_value) === Number(peakPoint.reading_value)) {
              if (moment(readings[i].reading_time).valueOf() < moment(peakPoint.reading_time).valueOf()) {
                peakPoint = readings[i];
                continue;
              } else {
                continue;
              }
            }
            peakPoint = readings[i];
          }
        }
        let floodStatus = 'Normal';
        if (peakPoint.reading_value < currentGaugeThresholds.el_fld_act) floodStatus = 'Normal';
        else if (currentGaugeThresholds.el_chtop && peakPoint.reading_value < currentGaugeThresholds.el_chtop) floodStatus = 'Near Flooding';
        else if (currentGaugeThresholds.el_chtop && peakPoint.reading_value >= currentGaugeThresholds.el_chtop) floodStatus = 'Flooding';

        summaryTableStateConfig.keyValueArray.push(
          {
            key: 'stagePeak',
            value: peakPoint.reading_value,
          },
          {
            key: 'timeOfPeak',
            value: convertToLocal(peakPoint.reading_time),
          },
          {
            key: 'floodStatus',
            value: floodStatus,
          },
        );
      }
      if (device === 'FLOW') {
        summaryTableStateConfig.keyValueArray.push({
          key: 'flow',
          value: max_reading,
        });
      }
    });
    await reduxStore.dispatch(setSummaryTableRowValues(summaryTableStateConfig));
  }
};
