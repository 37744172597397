import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  tableHead: {
    height: 34,
  },
});

const CondensedCell = withStyles({
  root: {
    padding: 0,
    fontSize: 13,
    lineHeight: '14px',
    paddingBottom: 2,
  },
})(TableCell);

const StartCell = withStyles({
  root: {
    padding: 0,
    paddingLeft: 10,
    fontSize: 13,
  },
})(TableCell);

const sourceAlias = {
  Observed: 'HCFCD Obs.',
  H0: 'HCFCD Fcst.',
  V0: 'VIEUX Fcst.',
  M0: 'MRMS Fcst.',  
  R0: 'HRRR Fcst.',
  N0: 'NBM Fcst.',
  A0: 'HRRR-MA Fcst.',
  G0: 'NSSL Fcst.'
};

const SummaryTable = ({ summaryTable, currentUser, firstForecastPoint, graphTimeSeries }) => {
  const classes = useStyles();
  const [peakPoint, setPeakPoint] = useState();
  const observed = summaryTable.find((row) => row.scenario === 'Observed');

  useEffect(() => {
    let peak = graphTimeSeries[graphTimeSeries.length - 1];
    for (let i = graphTimeSeries.length - 1; i >= 0; i -= 1) {
      if (graphTimeSeries[i].y >= peak.y && (moment(graphTimeSeries[i].x).valueOf() < moment(peak.x).valueOf())) {
        peak = graphTimeSeries[i];
      }
    }
    setPeakPoint(peak);
  }, [graphTimeSeries]);

  return (
    <Table size="small" className={classes.table} aria-label="simple table">
      <TableHead className={classes.tableHead}>
        <TableRow>
          <StartCell><strong>Scenario</strong></StartCell>
          <CondensedCell align="center"><strong>Flood<br />Status</strong></CondensedCell>
          <CondensedCell align="center"><strong>Peak WSEL<br />(FT)</strong></CondensedCell>
          <CondensedCell align="center"><strong>Time of Peak<br />(CDT/CST)</strong></CondensedCell>
          <CondensedCell align="center"><strong>Flow<br />(CFS)</strong></CondensedCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* Bring in redux state to determine peak values from 'graphTimeSeries' state */}
        {observed && (
          <TableRow key={observed.scenario}>
            <StartCell component="th" scope="row">
              {sourceAlias.Observed}
            </StartCell>
            <CondensedCell align="center">{observed.floodStatus || ''}</CondensedCell>
            <CondensedCell align="center">{peakPoint?.y || observed.stagePeak || ''}</CondensedCell>
            <CondensedCell align="center">{moment(peakPoint?.x || observed.timeOfPeak).format('MM/DD/YY HH:mm') || ''}</CondensedCell>
            <CondensedCell align="center">{observed.flow || ''}</CondensedCell>
          </TableRow>
        )}
        {summaryTable.map((row) => {
          if (row.scenario === 'Observed') return;
          if (currentUser.role === 'STANDARD_USER' && row.scenario !== 'H0') return;
          if (!row.stagePeak) return;
          if (firstForecastPoint && !Object.keys(firstForecastPoint).includes('max_forecast_stage')) return;

          return (
            <TableRow key={row.scenario}>
              <StartCell component="th" scope="row">
                {row.scenario ? sourceAlias[row.scenario] : ''}
              </StartCell>
              <CondensedCell align="center">{row.floodStatus || ''}</CondensedCell>
              <CondensedCell align="center">{row.stagePeak || ''}</CondensedCell>
              <CondensedCell align="center">{moment(row.timeOfPeak).format('MM/DD/YY HH:mm') || ''}</CondensedCell>
              <CondensedCell align="center">{row.flow || ''}</CondensedCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const mapStateToProps = ({ summaryTable, currentUser, graphTimeSeries, forecastIconData: [firstForecastPoint] }) => ({ summaryTable, currentUser, firstForecastPoint, graphTimeSeries });

export default connect(mapStateToProps)(SummaryTable);
