import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';

import { makeStyles } from '@material-ui/core/styles';
import { createDispatchBindings } from '../../../utils/redux';
import { plotLineColorsArray, thresholdConfig, stageFlowLineColors, stageFlowThresholds, stageFlowSourceAlias,stageFlowSourceAliasReverse} from '../../../config/constants';
import { setGraphTimeSeries } from '../../../reducers/graphTimeSeries';
import ResetZoomButton from '../ResetZoomButton';
import config from '../../../config';
import history from '../../../history';
import clampTimeline from '../../../utils/clampTimeline';
import { setToggleState } from '../../../reducers/gaugeThresholds';
import {setInvisibleForecastModel,removeForecastModel} from '../../../reducers/hiddenForecastModels';
import { convertToLocal } from '../../../utils/convertTime';
import  store  from '../../../store';
import { fetchForecastIconData } from '../../../reducers/forecastIconData';


const { drawerHeight } = config;
const DEVICE_for_MEAN = 'mean';
const Display_Caption_For_Mean='Best Source';
const DEVICE_For_ERR_LOWER= 'err_lower';
const DEVICE_for_ERR_UPPER= 'err_upper';

Chart.register(annotationPlugin);
Chart.register(zoomPlugin);

const useStyles = makeStyles({
  chartWrapper: {
    position: 'relative',
    width: '100%',
    height: ({ infoDrawer }) => (infoDrawer ? `calc(${drawerHeight}px - 90px)` : '100%'),
    paddingTop: 15,
    paddingRight: 20,
  },
  tooltip: {
    backgroundColor: '#666',
    borderRadius: 5,
    color: '#fff',
    position: 'absolute',
  },
});

let hovering = false;
let tooltip;
let legendVisibility = null;

const chartConfig = {
  type: 'line',
  data: {},
  options: {
    // give hover tooltip for all line values closest to the cursor
    // hover shows without having cursor intersect with point
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'x',
    },
    // prevents animations
    animation: {
      duration: 0,
    },
    // prevents canvas height changes with width changes
    maintainAspectRatio: false,
    // remove points on lines
    elements: {
      point: {
        radius: 0,
      },
    },
    // axis options
    scales: {
      x: {
        type: 'time',
        time: {
          displayFormats: {
            millisecond: 'MM/DD/YYYY HH:mm',
            second: 'MM/DD/YYYY HH:mm',
            minute: 'MM/DD/YYYY HH:mm',
            hour: 'MM/DD/YYYY HH:mm',
            day: 'MM/DD/YYYY HH:mm',
            week: 'MM/DD/YYYY HH:mm',
            month: 'MM/DD/YYYY HH:mm',
            quarter: 'MM/DD/YYYY HH:mm',
            year: 'MM/DD/YYYY HH:mm',
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Stage (ft)',
        },
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          callback: (value) => Number(value).toFixed(2),
        },
      },
      // y1: {
      //   title: {
      //     display: true,
      //     text: 'Flow (cfs)',
      //   },
      //   type: 'linear',
      //   display: true,
      //   position: 'right',
      //   // grid line settings
      //   grid: {
      //     drawOnChartArea: false, // only want the grid lines for one axis to show up
      //   },
      // },
    },
    plugins: {
      autocolors: false,
      legend: {
        onClick: (_, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          const { hiddenForecastModels } = store.getState();
          if (ci.isDatasetVisible(index)) {
            ci.hide(index);
            legendItem.hidden = true;

            showOrHideUncertainityBand(legendItem,legend, true);
            let model = stageFlowSourceAliasReverse[legendItem.text];          
            store.dispatch(setInvisibleForecastModel({value: model}));
            store.dispatch(fetchForecastIconData(hiddenForecastModels.selectedForecastId));
          } else {
            ci.show(index);
            legendItem.hidden = false;

            showOrHideUncertainityBand(legendItem,legend, false);
            store.dispatch(removeForecastModel({value: stageFlowSourceAliasReverse[legendItem.text]}));
            store.dispatch(fetchForecastIconData(hiddenForecastModels.selectedForecastId));
          }
          legendVisibility = legend.legendItems.map((item) => ({ text: item.text, hidden: item.hidden }));
          ci.update();
        },
        labels: {
          filter: (item) => !item.text.includes('remove') && !item.text.includes('UPPER') && !item.text.includes('LOWER'),
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;

            let legends = [];
            for (let i = 0; i < datasets.length; i++) {
              const meta = chart.getDatasetMeta(i)
              
              legends.push({
                text: `${datasets[i].label}`,
                fillStyle: datasets[i].backgroundColor,
                datasetIndex: i,                
                hidden: !meta.visible                
              });
            }
            return legends;
           }
        },
        onHover: (event, legendElement) => {
          if (hovering) {
            return;
          }
          hovering = true;
          if (tooltip) {
            tooltip.current.innerHTML = `Toggle ${legendElement.text}`;
            tooltip.current.style.padding = '2px 4px';
            tooltip.current.style.left = `${event.x - 50}px`;
            tooltip.current.style.top = `${event.y - 20}px`;
          }
        },
        onLeave: () => {
          hovering = false;
          if (tooltip) {
            tooltip.current.innerHTML = '';
            tooltip.current.style.padding = '0';
          }
        },
       
      },
      annotation: {
        annotations: {},
      },
      zoom: {
        zoom: {
          drag: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
  },
};

function showOrHideUncertainityBand(legendItem, legend,blnIsHidden)
{
    const ci = legend.chart;
    if (legendItem.text.toLowerCase().includes(Display_Caption_For_Mean.toLowerCase()))
    {
        const datasets = legend.chart.data.datasets;
        for (let i = 0; i < datasets.length; i++) {
          if (datasets[i].label.toLowerCase().includes(DEVICE_For_ERR_LOWER) || datasets[i].label.toLowerCase().includes(DEVICE_for_ERR_UPPER))
          {
              if (blnIsHidden)
                ci.hide(i);
              else
                ci.show(i); 
          }
        }
        legend.legendItems.forEach((item) =>
        {
            if (item.text.toLowerCase().includes(DEVICE_For_ERR_LOWER)  || item.text.toLowerCase().includes(DEVICE_for_ERR_UPPER)) 
                item.hidden=blnIsHidden;
        });       
    }
}


let chartData;
export const getStageFlowChartData = () => chartData;

const StageFlowPlot = ({ selectedGaugeData, thresholds, gaugeForecast, modal, setGraphTimeSeries, infoDrawer, thresholdToggleState, dateRange, currentUser, setToggleState, setInvisibleForecastModel,removeForecastModel ,fetchForecastIconData}) => {
  const gaugeId = history.location.pathname.split('/')[2];
  const stageData = selectedGaugeData['20'];
  const flowData = selectedGaugeData['25'];
  const classes = useStyles({ infoDrawer });
  const chartContainer = useRef(null);
  const tooltipRef = useRef(null);
  const currentPage = history.location.pathname.split('/')[1];
  const [stageFlowChart, setStageFlowChart] = useState();

  useEffect(() => {
    tooltip = tooltipRef;
  }, [tooltipRef]);

  useEffect(() => {
    if (gaugeId && thresholds?.length) {
      const gaugeThresholds = {};
      const currentGaugeThresholds = thresholds.find((gauge) => Number(gauge.gauge_id) === Number(gaugeId));
      if (currentGaugeThresholds) {
        stageFlowThresholds.forEach((threshold) => {
          if (currentGaugeThresholds[threshold] && Number(currentGaugeThresholds[threshold]) !== -999) gaugeThresholds[threshold] = true;
        });
        setToggleState(gaugeThresholds);
      }
    }
  }, [gaugeId, thresholds]);

  useEffect(() => {
    if (stageFlowChart) {
      let changed = false;
      if (stageFlowChart.options?.plugins?.annotation?.annotations?.box1 && stageFlowChart.options?.plugins?.annotation?.annotations?.box1?.display !== thresholdToggleState.el_fld_act) {
        stageFlowChart.options.plugins.annotation.annotations.box1.display = thresholdToggleState.el_fld_act;
        changed = true;
      }
      if (stageFlowChart.options?.plugins?.annotation?.annotations?.box2 && stageFlowChart.options?.plugins?.annotation?.annotations?.box2?.display !== thresholdToggleState.el_chtop) {
        stageFlowChart.options.plugins.annotation.annotations.box2.display = thresholdToggleState.el_chtop;
        changed = true;
      }
      if (changed) stageFlowChart.update();
    }
  }, [thresholdToggleState]);

  // FORMAT STAGE AXIS
  useEffect(() => {
    if (![stageData, flowData, gaugeForecast, thresholds].every((value) => value === false) && stageFlowChart && thresholds?.length) {
      // blank data to keep timeline consistent with start/end date selection
      const { start, end } = dateRange;
      const blankTimeline = [{ x: moment(start).format(), y: null }, { x: moment(end).format(), y: null }];

      // STAGE
      let stageSeries = [];
      if (stageData?.length) {
        stageSeries = stageData.map((point) => ({ x: convertToLocal(point.data_time), y: Number(point.data_value) })).reverse();
        stageSeries.sort((a, b) => moment(a.x).diff(moment(b.x)));
        setGraphTimeSeries(clampTimeline({ data: stageSeries, dateKey: 'x', start, end }));
      }

      // FORECAST
      // let forecastX = [];
      const forecastSeriesArray = [];
      if (gaugeForecast?.length) {
        for (let j = 0; j < Object.values(gaugeForecast).length; j += 1) {
          forecastSeriesArray[j] = gaugeForecast[j].full_readings.map((reading) => ({ x: convertToLocal(reading.reading_time), y: reading.reading_value }));
          forecastSeriesArray[j].sort((a, b) => moment(a.x).diff(moment(b.x)));
        }
        stageSeries = [{ x: forecastSeriesArray[forecastSeriesArray.length - 1]?.x, y: null }, ...stageSeries];
      }

      // CHART SERIES
      const chartSeries = [];
      chartSeries.push({
        data: blankTimeline,
        label: 'remove',
      });
      if (stageSeries.length) {
        chartSeries.push({
          label: 'HCFCD Obs.',
          data: clampTimeline({ data: stageSeries, dateKey: 'x', start, end }),
          borderColor: stageFlowLineColors.HCFCD,
          backgroundColor: stageFlowLineColors.HCFCD,
          borderWidth: 2,
          yAxisID: 'y',
        });
      }
      // if (flowSeries?.length) {
      //   chartSeries.push({
      //     label: 'Flow',
      //     data: clampTimeline({ data: flowSeries, dateKey: 'x', start, end }),
      //     borderColor: plotLineColorsArray.stage[1],
      //     backgroundColor: plotLineColorsArray.stage[1],
      //     borderWidth: 2,
      //     yAxisID: 'y1',
      //   });
      // }
      if (forecastSeriesArray.length) {        
        for (const [i, forecastData] of gaugeForecast.entries()) {
          if (currentUser.role !== 'STANDARD_USER' || forecastData.reading_source === 'H0') {
            const forecastDataDevice = forecastData.device.toLowerCase();
            if ((forecastData.device === 'STAGE') && (forecastDataDevice !== DEVICE_for_ERR_UPPER && forecastDataDevice !== DEVICE_For_ERR_LOWER && forecastDataDevice !== DEVICE_for_MEAN)) { // Temporarily disable FLOW forecast
              // if ((forecastData.device === 'STAGE' || forecastData.device === 'FLOW') && (forecastData.reading_source !== 'ERR_UPPER' && forecastData.reading_source !== 'ERR_LOWER')) { // Temporarily disable FLOW forecast
              chartSeries.push({
                label: stageFlowSourceAlias[`${forecastData.reading_source}${forecastData.device}`] || `${forecastData.device} - ${forecastData.reading_source}`,
                data: clampTimeline({ data: forecastSeriesArray[i], dateKey: 'x', start, end }),
                borderColor: stageFlowLineColors[`${forecastData.reading_source}${forecastData.device}`] || plotLineColorsArray.stage[i + 2],
                backgroundColor: stageFlowLineColors[`${forecastData.reading_source}${forecastData.device}`] || plotLineColorsArray.stage[i + 2],
                borderWidth: 2,
                yAxisID: forecastData.device === 'STAGE' ? 'y' : 'y1',
              });
            }
          }
        }
        // ADD UPPER AND LOWER BOUNDS TOGETHER SO FILL BETWEEN LINES WORKS
        let upperErrorIndex;
        let lowerErrorIndex;
        let meanErrorIndex;
        const meanError = gaugeForecast.find(({ device }, index) => {
          if (device.toLowerCase() === DEVICE_for_MEAN) {
            meanErrorIndex = index;
            return true;
          }
        });
        const upperError = gaugeForecast.find(({ device }, index) => {
          if (device.toLowerCase() === DEVICE_for_ERR_UPPER) {
            upperErrorIndex = index;
            return true;
          }
        });
        const lowerError = gaugeForecast.find(({ device }, index) => {
          if (device.toLowerCase() === DEVICE_For_ERR_LOWER) {
            lowerErrorIndex = index;
            return true;
          }
        });
        if (meanError) {
          chartSeries.push({
            label: getLabelTextBasedOnReadingSrc(gaugeForecast) ,
            data: clampTimeline({ data: forecastSeriesArray[meanErrorIndex], dateKey: 'x', start, end }),
            borderColor: getColorBasedOnReadingSource(gaugeForecast), //'#e6194B',
            backgroundColor: getColorBasedOnReadingSource(gaugeForecast), //'#e6194B',
            borderWidth: 2,
            borderDash: [4,4],
            yAxisID: meanError.device.toLowerCase() === DEVICE_for_MEAN ? 'y' : 'y1',
          });
        }
        if (upperError) {
          chartSeries.push({
            label: `${upperError.device} -  ${stageFlowSourceAlias[`${upperError.reading_source}STAGE`]}`,
            data: clampTimeline({ data: forecastSeriesArray[upperErrorIndex], dateKey: 'x', start, end }),
            borderColor: 'rgba(0,0,0,0.2)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderWidth: 0,
            yAxisID: upperError.device.toLowerCase() === DEVICE_for_ERR_UPPER ? 'y' : 'y1',
            fill: '+1',
          });
        }
        if (lowerError) {
          chartSeries.push({
            label: `${lowerError.device} - ${stageFlowSourceAlias[`${lowerError.reading_source}STAGE`]}`,
            data: clampTimeline({ data: forecastSeriesArray[lowerErrorIndex], dateKey: 'x', start, end }),
            borderColor: 'rgba(0,0,0,0.2)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderWidth: 0,
            yAxisID: lowerError.device.toLowerCase() === DEVICE_For_ERR_LOWER ? 'y' : 'y1',
            fill: false,
          });
        }
      }

      function getColorBasedOnReadingSource(gaugeForecast)
      {
        let currentReadingSource='';
        gaugeForecast.forEach(({ device ,reading_source }) => {
          if (device.toLowerCase() === DEVICE_for_MEAN) 
            currentReadingSource= reading_source;
        });
        return stageFlowLineColors[`${currentReadingSource}STAGE`];
      };

      function getLabelTextBasedOnReadingSrc(gaugeForecast)
      {
        let currentReadingSource='';
        gaugeForecast.forEach(({ device ,reading_source }) => {
          if (device.toLowerCase() === DEVICE_for_MEAN) 
            currentReadingSource= reading_source;
        });
        //return `Best:${stageFlowSourceAlias[`${currentReadingSource}STAGE`]}`;
        return 'Best Source:' + stageFlowSourceAlias[`${currentReadingSource}STAGE`];
      };
      
      // THRESHOLDS
      const gaugeThresholds = thresholds.find(({ gauge_id }) => Number(gauge_id) === Number(gaugeId));
      if (gaugeThresholds && Object.keys(gaugeThresholds)?.length) {
        const { el_fld_act, el_chtop } = gaugeThresholds;
        // Add threshold annotations
        if (el_fld_act && Number(el_fld_act) !== -999) {
          stageFlowChart.options.plugins.annotation.annotations.box1 = {
            type: 'line',
            yMin: el_fld_act,
            yMax: el_fld_act,
            borderColor: '#fca503',
            borderWidth: 1,
            borderDash: [4, 4],
            display: thresholdToggleState ? thresholdToggleState.el_fld_act : true,
            label: {
              content: 'Action',
              enabled: true,
              position: 'start',
              xPadding: 2,
              yPadding: 2,
              font: {
                size: 10,
              },
              color: 'black',
              backgroundColor: thresholdConfig.el_fld_act.color,
            },
          };
        }
        if (el_chtop && Number(el_chtop) !== -999) {
          stageFlowChart.options.plugins.annotation.annotations.box2 = {
            type: 'line',
            yMin: el_chtop,
            yMax: el_chtop,
            borderColor: '#fc0303',
            borderWidth: 1,
            borderDash: [4, 4],
            display: thresholdToggleState ? thresholdToggleState.el_chtop : true,
            label: {
              content: 'Channel Top',
              enabled: true,
              position: 'start',
              xPadding: 2,
              yPadding: 2,
              font: {
                size: 10,
              },
              color: 'black',
              backgroundColor: thresholdConfig.el_chtop.color,
            },
          };
          stageFlowChart.update();
        }
      }

      // CHART OPTIONS
      // Add forecast start x-axis line annotation
      if (forecastSeriesArray.length) {
        const forecastStartDate = moment(convertToLocal(gaugeForecast[0].run_time)).format();
        stageFlowChart.options.plugins.annotation.annotations.line3 = {
          type: 'line',
          xMin: forecastStartDate,
          xMax: forecastStartDate,
          borderColor: '#000',
          borderWidth: 1,
          borderDash: [4, 4],
        };
        stageFlowChart.update();
      }


      // SET PLOT DATA
      if (chartSeries?.length && stageFlowChart) {
        stageFlowChart.data.datasets = chartSeries;
        if (legendVisibility?.length) {
          legendVisibility.forEach((item) => {
            if (item.hidden && !item.text.toLowerCase().includes(Display_Caption_For_Mean.toLowerCase())) {
              for (let i = 0; i < chartSeries.length; i++) {
                if (chartSeries[i].label === item.text) {                  
                  stageFlowChart.getDatasetMeta(i).hidden = item.hidden;                  
                  break;
                }
              }
            }
            else
            {
              for (let i = 0; i < chartSeries.length; i++) {
                if (chartSeries[i].label.toLowerCase().includes(Display_Caption_For_Mean.toLowerCase())) {                  
                  stageFlowChart.getDatasetMeta(i).hidden = item.hidden;
                  if (item.text.toLowerCase().includes(Display_Caption_For_Mean.toLowerCase()))
                  {
                    for (let j = 0; j < chartSeries.length; j++) {
                      if (chartSeries[j].label.toLowerCase().includes(DEVICE_For_ERR_LOWER) || chartSeries[j].label.toLowerCase().includes(DEVICE_for_ERR_UPPER))
                          stageFlowChart.getDatasetMeta(j).hidden = item.hidden;
                    }
                  }
                  break;
                }
            }
          }
          });
        }
        stageFlowChart.update();
        chartData = chartSeries;
      }
    }
  }, [stageData, flowData, gaugeForecast, thresholds, stageFlowChart, modal.open]);

  useEffect(() => {
    if (chartContainer && chartContainer.current && !stageFlowChart) {
      const chartInstance = new Chart(chartContainer.current, chartConfig);      
      setStageFlowChart(chartInstance);
    }
  }, [chartContainer, currentPage]);

  return (
    <div className={classes.chartWrapper}>
      <ResetZoomButton chartRef={stageFlowChart} />
      <canvas id="stage-chart" ref={chartContainer} width="100%" />
      <div className={classes.tooltip} ref={tooltipRef} />
    </div>
  );
};

const mapStateToProps = ({ gaugeForecast, forecastList, modal, selectedGaugeData, dateRange, currentUser }) => ({ gaugeForecast, forecastList, modal, selectedGaugeData, dateRange, currentUser });
const mapDispatchToProps = createDispatchBindings({ setGraphTimeSeries, setToggleState,setInvisibleForecastModel,removeForecastModel,fetchForecastIconData });

export default connect(mapStateToProps, mapDispatchToProps)(StageFlowPlot);
